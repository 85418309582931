import { Icon, Paragraph, TriggerButton } from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { memo, useMemo } from 'react';

import {
  useDepartureAirportsInformation,
  useSearchSelectionChildrenCount,
  useSearchSelectionTravellerCount,
} from './SearchStoreProperties';
import { ClassNameProps } from '@ComponentProps';
import { isMonthFlexibility } from '@Components/SearchForm/isMonthFlexibility';
import { useSearchConfigurationContext } from '@Components/SearchForm/SearchConfigurationContext';
import { useDate } from '@Dates/useDateHook';
import { useSearchSelectionStore } from '@Stores/StoreContext';
import { DestinationInput } from '@UX/components/Search/DestinationInput/DestinationInput';

export function useSearchSelectionFlexibility() {
  const { t } = useTranslation();
  const flexibility = useSearchSelectionStore((state) => state.flexibility);

  if (!flexibility || isMonthFlexibility(flexibility)) {
    return;
  }

  return `(${t('newFlexibility.flex', { context: String(flexibility) })})`;
}

interface DynamicPackageSearchSummaryCondensedProps extends ClassNameProps {
  onEdit: () => void;
  showInputTitleOnMobile?: boolean;
}

export const DynamicPackageSearchSummaryCondensed: React.FC<DynamicPackageSearchSummaryCondensedProps> =
  memo(({ className, onEdit, showInputTitleOnMobile }) => {
    const { t } = useTranslation();
    const { formatCalendarDate, formatAbbreviatedMonthYear } = useDate();
    const { showDestinationInput } = useSearchConfigurationContext();

    const { date, nights, rooms, isMonthSelected } = useSearchSelectionStore(
      ({ date, nights, rooms, isMonthSelected }) => ({
        date,
        nights,
        rooms,
        isMonthSelected: isMonthSelected(),
      }),
    );

    const formattedDate = useMemo(() => {
      if (!date) {
        return '';
      }

      return isMonthSelected ? formatAbbreviatedMonthYear(date) : formatCalendarDate(date);
    }, [date, formatAbbreviatedMonthYear, formatCalendarDate, isMonthSelected]);

    const flexibility = useSearchSelectionFlexibility();
    const childrenCount = useSearchSelectionChildrenCount();
    const travellerCount = useSearchSelectionTravellerCount();
    const airportNames =
      useDepartureAirportsInformation()
        .map(({ airport: { name } }) => name)
        .join(', ') || t('anyAirport');

    return (
      <div className={className}>
        {showDestinationInput && (
          <DestinationInput
            hasInputTitle
            showInputTitleOnMobile={showInputTitleOnMobile}
            allowTagsRemoval
            sx={{ marginBottom: '3xs' }}
          />
        )}

        <TriggerButton
          data-id="expand-search-form-btn"
          trackingAction="condensed-search-summary"
          sx={{
            borderWidth: 'outlinedStrokeWeight',
            borderStyle: 'solid',
            borderColor: 'strokeDarkneutral',
            borderRadius: '8',
            backgroundColor: 'backgroundWhite',
            display: 'block',

            '&:hover': {
              borderColor: 'strokeDark',
            },
          }}
          onTrigger={onEdit}
        >
          <section
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 40px',
              paddingLeft: 'xs',
              paddingY: '2xs',
              columnGap: '3xs',
            }}
          >
            <Paragraph
              variant="small"
              sx={{ width: '100%' }}
            >
              {[
                airportNames,
                formattedDate,
                flexibility,
                t('nightsLabel', { count: nights }),
                t('rooms.labels.room', { count: rooms.length }),
                t(childrenCount > 0 ? 'rooms.labels.guest' : 'rooms.labels.adult', {
                  count: travellerCount,
                }),
              ]
                .filter(Boolean)
                .join(' / ')}
            </Paragraph>
            <div
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                name="Actions/Edit"
                size="16"
              />
            </div>
          </section>
        </TriggerButton>
      </div>
    );
  });
